import { useState,useRef,useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import randomstring from "randomstring";

import { InputText } from "primereact/inputtext";
import { Dropdown,DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumber, InputNumberChangeEvent } from "primereact/inputnumber";
import { Slider, SliderChangeEvent } from "primereact/slider";
import { ColorPicker, ColorPickerChangeEvent } from 'primereact/colorpicker';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

export const Properties = (props) => {
    const{ 
        legend,        
        rerenderTrigger,
        setRerenderTrigger,
        blocks,
        elements,
        options,
        LC_Legend,
        LC_Class,
        classesNumber,
        LC_ClassCharacteristics,
        LC_HorizontalPatterns,
        horizontalPatternNumber,
        LC_Strata,        
        strataNumber,
        LC_Properties,
        stratumPropertyNumber,
        LC_Characteristics,
        stratumCharacteristicNumber,        
        activeLCElement,
        setActiveLCElement,
        displayFormElements,
        control,
        errors,
        handleSubmit,
        fields, 
        append, 
        remove, 
        move,
        insert,
        getValues,
        setValue,
        watch,
        reset,
        LC_Objectfilter,
     } = props;
     
     /*const {
        control,
        formState: { errors },
        register,
        unregister,
        handleSubmit,
        getValues,
        setValue,
        watch,
        reset
    } = useForm({ });
    const { 
        fields, 
        append, 
        remove, 
        move,
        insert
        } = useFieldArray({
        control,
        name: "items"
        });
    const getFormErrorMessage = (name:any) => {
        return errors[name] ? <small className="p-error">This field is required</small> : <small className="p-error">&nbsp;</small>;
    };

    const displayFormElements = (elements,elementSuffix='')=>{
        var ctlr = [];
        for (const [key, value] of Object.entries(elements)) {            
            let component = null;
            let required = "";            
            switch(value['element_type']) {
                case "Range":
                    component = <Controller
                                key={"Range"+key}
                                name={value['element_name']+elementSuffix}
                                control={eval('control')}
                                defaultValue={[value['element_rules']['min'],value['element_rules']['max']]}
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1" id={field.name}>
                                        <span className="p-inputgroup-addon text-xs p-2 pl-3 pr-3 m-0">{value['element_label']}:</span>
                                            <div className="card flex flex-column w-full gap-0">
                                            <Slider name={field.name} value={field.value as any} onChange={(e: SliderChangeEvent) => field.onChange(e.value as number[])} className="p-inputtext-sm text-xs p-0 m-0" range style={{width: '100%', alignSelf: 'center',verticalAlign: 'middle'}} min={value['element_rules']['min']} max={value['element_rules']['max']} required={value['element_rules']['required']} />
                                            <span className="text-xs p-0 m-0 mt-2" style={{ textAlign: 'center' }}>{field.value[0]} - {field.value[1]}</span>
                                            </div>
                                        <span className="p-inputgroup-addon text-xs p-2 m-0">{value['element_rules']['symbol']}</span>                                    
                                    </div>
                                    )}
                                />;
                    break;
                case "Dropdown":                
                    component = <Controller
                                key={"Dropdown"+key}
                                name={value['element_name']+elementSuffix}
                                control={eval('control')}
                                defaultValue=""
                                rules={{ required:value['element_rules']['required'] }}
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1" id={field.name}>
                                        <span className="p-inputgroup-addon text-s p-0 m-0">
                                            <i className="pi pi-pencil"></i>
                                        </span>
                                        <Dropdown inputId={field.name} name={field.name} value={field.value} onChange={(e: DropdownChangeEvent) => {field.onChange(e.value);formReconfig(value['element_rules']['options_name'],e.value)}} inputRef={field.ref} options={eval(value['element_rules']['list'])} optionLabel="label" placeholder={"Select a "+value['element_label']} className="p-inputtext-sm text-xs p-0 m-0" editable tooltip={value['element_label']} tooltipOptions={{ event: 'both' }} />
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">{value['element_rules']['symbol']}</span>
                                    </div>
                                    )}
                                />;        
                    break;            
                case "Number":
                    if(value['element_name'] != "class_id"){
                        component = <Controller
                                key={"Number"+key}
                                name={value['element_name']+elementSuffix}
                                control={eval('control')}
                                defaultValue={value['element_rules']['min']}
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1" id={field.name}>
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">
                                            <i className="pi pi-pencil"></i>
                                        </span>
                                        <InputNumber name={field.name} value={field.value} className="p-inputtext-sm text-xs p-1 m-0" onValueChange={(e) => field.onChange(e.target.value)} showButtons buttonLayout="horizontal" style={{ width: '100%' }} min={value['element_rules']['min']} max={value['element_rules']['max']} placeholder={value['element_label']} required={value['element_rules']['required']} tooltip={value['element_label']} tooltipOptions={{ event: 'both' }} />                                        
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">{value['element_rules']['symbol']}</span>
                                    </div>
                                    )}
                                />;
                    }
                    else{
                        component = <Controller
                                key={"Number"+key}
                                name={value['element_name']+elementSuffix}
                                control={eval('control')}
                                defaultValue={value['element_rules']['min']}
                                render={({ field, fieldState }) => (                                    
                                        <input type="hidden" id={field.name} name={field.name} value={field.value} />
                                    )}
                                />;                            
                    }
                    break;
                case "Color":
                    component = <Controller
                                key={"Color"+key}
                                name={value['element_name']+elementSuffix}
                                control={eval('control')}                            
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1" id={field.name}>
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">
                                            <i className="pi pi-palette"></i>
                                        </span>
                                        <div className="flex flex-row" style={{ width: '100%' }}>
                                            <ColorPicker format="hex" className="p-0 m-0" onChange={(e) => field.onChange(e.value)} style={{ width: '40px' }} />
                                            <p className="flex align-self-center p-0 m-0">&lt;--select color here #</p>
                                            <InputText name={field.name} value={field.value} className="p-inputtext-sm text-s  p-2 m-0" style={{ width: '30%'}} prefix="#" />
                                        </div>
                                    </div>
                                    )}
                                />;
                    break;
                default:
                    component = <Controller
                                key={key}
                                name={value['element_name']+elementSuffix}
                                control={eval('control')}
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1" id={field.name}>
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">
                                            <i className="pi pi-pencil"></i>
                                        </span>
                                        <InputText name={field.name} value={field.value} className="p-inputtext-sm text-xs p-1 m-0" onChange={(e) => field.onChange(e.target.value)} style={{ width: '100%' }} placeholder={value['element_label']} required={value['element_rules']['required']} tooltip={value['element_label']} tooltipOptions={{ event: 'both' }} />
                                    </div>
                                    )}
                                />;
            }
            ctlr.push(component);
        };        
        return (<>{ctlr}</>);
    }*/
    
    const toast = useRef(null);

    const updateReferencedProperty = (Reference,reference_id,id,Data)=>{
        let ReferenceFile = "LC_"+Reference+".current";      
        if(Reference === "Properties"){
            let elementReference = blocks.current['LC_Block'].filter(element => element.block_id === parseInt(Data.objectID));
            let newElement = {StratumID: parseInt(Data.elementID), BlockID: parseInt(Data.objectID)};
            Object.values(elementReference[0]['elements']).forEach((item)=>{
                let content = item['element_name'] as any;
                if(typeof(Data[content]) === "object"){
                    if(!Array.isArray(Data[content])){
                        if(Data[content]['label'] !== undefined){
                            Data[content] = Data[content]['label'];
                        }
                    }
                }
                newElement = {...newElement, [content]: Data[content]};
            });
            let Elements = eval(ReferenceFile+'.filter(content => ((content.StratumID !== '+parseInt(Data.elementID)+') || (content.StratumID === '+parseInt(Data.elementID)+' && content.BlockID !== '+parseInt(Data.objectID)+')))');
            Elements = [...Elements, newElement];
            eval(ReferenceFile+' = Elements');
            toast.current.show({ severity: 'success', summary: 'Property Updated', detail: "Element Property Updated"});
        }
        else{
            if(Reference === "Class" || Reference === "HorizontalPatterns" || Reference === "Strata")
                ReferenceFile = ReferenceFile+"["+reference_id+"]";
            Object.keys(Data).forEach((element)=>{
                let appendix = "";
                if(isNaN(id))
                    appendix = "."+id;
                if(Data[element] !== undefined){
                    if(Data[element] === null)
                        Data[element] = "";
                    if(element === "class_id")
                        Data[element] = parseInt(Data[element]);
                    if(typeof(Data[element]) === "object"){
                        if(Data[element].label !== undefined)
                            eval([ReferenceFile]+""+[appendix]+""+"['"+[element]+"']"+"='"+Data[element].label+"'");
                        else
                            eval([ReferenceFile]+""+[appendix]+""+"['"+[element]+"']"+"=["+Data[element]+"]");
                    }
                    else if(typeof(Data[element]) === "number")
                        eval([ReferenceFile]+""+[appendix]+""+"['"+[element]+"']"+"="+Data[element]);
                    else
                        eval([ReferenceFile]+""+[appendix]+""+"['"+[element]+"']"+"='"+Data[element]+"'");
                    toast.current.show({ severity: 'success', summary: 'Property Submitted', detail: [element]+": "+Data[element]});
                }
            })
        }
        setRerenderTrigger(Reference+randomstring.generate(8)+d.getTime());
        return(true);    
    }

    const submitProperty = (data: any,event) => {
        event.preventDefault();
        let LCType = (document.getElementById("LCType") as HTMLInputElement).value;
        let elementID = (document.getElementById("elementID") as HTMLInputElement).value;
        let formElementID = (document.getElementById("formElementID") as HTMLInputElement).value;        
        let objectID = (document.getElementById("objectID") as HTMLInputElement).value;        
        let formValues = {};
        Object.keys(control._fields).map((field)=>{
            if(control._fields[field]['_f']['mount'])
                formValues = {...formValues, [field]: control._fields[field]['_f']['value']};
        });
        if(!updateReferencedProperty(LCType,objectID,formElementID,formValues))
            toast.current.show({ severity: 'error', summary: 'Undefined:', detail: 'No Property Type Identified', life: 60000});
        formReset();        
        let tempActiveLCElement = activeLCElement;
        const timer = setTimeout(() => {
            setActiveLCElement(false);
        }, 10);
        const timer2 = setTimeout(() => {
            setActiveLCElement(tempActiveLCElement);
        }, 15);
    };
    
    const [displayForm,setDisplayForm] = useState<any>();
    const [hideForm,setHideForm] = useState<boolean>(false);
    const [elementID,setElementID] = useState<any>();
    const [formElementID,setFormElementID] = useState<any>();
    const [objectID,setObjectID] = useState<any>();
    const [LCType,setLCType] = useState<any>();
    const [Delete,setDelete] = useState<any>();
    const [Add,setAdd] = useState<any>();
    const [resets, setResets] = useState<any>();
    const displays = useRef<any>([]);
    const reconfigs = useRef<any>([]);
    useEffect(()=>{
        displays.current = [];
        reconfigs.current = [];
        if(activeLCElement){            
            let elementKey = activeLCElement?.split("|");
            let resetter = [];
            let display = [];
            let reconfig = [];
            switch(elementKey.length) {
                case 2:
                    setHideForm(false);                    
                    setLCType('Legend');
                    setDelete(null);                    
                    setDisplayForm(displayFormElements(legend["LC_Legend"][0]["elements"]));
                    setElementID(elementKey[1]);
                    legend["LC_Legend"][0]["elements"].map((element)=>{                        
                        resetter.push('setValue("'+element['element_name']+'", "'+LC_Legend.current[element['element_name']]+'")');
                    });
                    resetter.push('setValue("formElementID", '+LC_Legend.current["id"]+')');
                    resetter.push('setValue("elementID", '+elementKey[1]+')');
                    setFormElementID(LC_Legend.current["id"]);
                    setObjectID(null);
                    setAdd(<Button id="Add" name="Add" label="Add Class" type="button" icon="fa fa-folder-open"  style={{ width: 'fit-contents', verticalAlign: 'center', fontSize: 'x-small', height: 40, backgroundColor: '#fff', color: '#000', borderRadius: 'var(--border-radius)' }} className="flex align-self-center" size="small" raised onClick={()=>addClass()} />);
                    setResets(resetter);
                    break;
                case 3:
                    setHideForm(false);
                    setLCType('Class');
                    setDelete(<Button id="Delete" name="Delete" label="Delete Class" type="button" icon="pi pi-trash"  style={{ width: 'fit-contents', verticalAlign: 'center', fontSize: 'x-small', height: 40, backgroundColor: '#aaa', color: '#fff', borderRadius: 'var(--border-radius)' }} className="flex align-self-center" size="small" raised onClick={()=>setConfirmClassDelete(true)} />);
                    setDisplayForm(displayFormElements(legend["LC_Class"][0]["elements"]));
                    setElementID(elementKey[elementKey.length-1]);
                    let Class = LC_Objectfilter(LC_Class.current, "class_id", elementKey[elementKey.length-1]);                    
                    if(Class.length > 0){
                        legend["LC_Class"][0]["elements"].map((element)=>{
                            resetter.push('setValue("'+element['element_name']+'", "'+Class[0][element['element_name']]+'")');
                        });
                        resetter.push('setValue("objectID", '+Class[0]["objectID"]+')');
                        resetter.push('setValue("formElementID", '+Class[0]["class_id"]+')');
                        resetter.push('setValue("elementID", '+elementKey[elementKey.length-1]+')');
                        setFormElementID(Class[0]["class_id"]);
                        setObjectID(Class[0]["objectID"]);
                    }
                    else
                        setObjectID(null);
                    setAdd(<Button id="Add" name="Add" label="Add Horizontal Pattern" type="button" icon="pi pi-bars"  style={{ width: 'fit-contents', verticalAlign: 'center', fontSize: 'x-small', height: 40, backgroundColor: '#fff', color: '#000', borderRadius: 'var(--border-radius)' }} className="flex align-self-center" size="small" raised onClick={()=>addHorizontalPattern(Class[0]["class_id"])} />);
                    setResets(resetter);
                    break;
                case 5:
                    setHideForm(false);
                    setLCType('HorizontalPatterns');
                    setDelete(<Button id="Delete" name="Delete" label="Delete Horizontal Pattern" type="button" icon="pi pi-trash"  style={{ width: 'fit-contents', verticalAlign: 'center', fontSize: 'x-small', height: 40, backgroundColor: '#aaa', color: '#fff', borderRadius: 'var(--border-radius)' }} className="flex align-self-center" size="small" raised onClick={()=>setConfirmHPDelete(true)} />);
                    setDisplayForm(displayFormElements(legend["LC_Patterns"][0]["elements"]));
                    setElementID(elementKey[elementKey.length-1]);
                    let HorizontalPattern = LC_Objectfilter(LC_HorizontalPatterns.current, "horizontal_pattern_id", elementKey[elementKey.length-1]);
                    if(HorizontalPattern.length > 0){
                        legend["LC_Patterns"][0]["elements"].map((element)=>{
                            if(typeof(HorizontalPattern[0][element['element_name']]) === "undefined" || HorizontalPattern[0][element['element_name']] === null || HorizontalPattern[0][element['element_name']] === "")
                                resetter.push('setValue("'+element['element_name']+'", "")');
                            else if(typeof(HorizontalPattern[0][element['element_name']]) === "object")
                                resetter.push('setValue("'+element['element_name']+'", ['+HorizontalPattern[0][element['element_name']][0]+','+HorizontalPattern[0][element['element_name']][1]+'])');                                
                            else if(typeof(HorizontalPattern[0][element['element_name']]) === "number")
                                resetter.push('setValue("'+element['element_name']+'", '+HorizontalPattern[0][element['element_name']]+')');
                            else
                                resetter.push('setValue("'+element['element_name']+'", "'+HorizontalPattern[0][element['element_name']]+'")');
                        }); 
                        resetter.push('setValue("objectID", '+HorizontalPattern[0]["objectID"]+')');
                        resetter.push('setValue("formElementID", '+HorizontalPattern[0]["horizontal_pattern_id"]+')');
                        resetter.push('setValue("elementID", '+elementKey[elementKey.length-1]+')');
                        setFormElementID(HorizontalPattern[0]["horizontal_pattern_id"]);
                        setObjectID(HorizontalPattern[0]["objectID"]);
                    }
                    else
                        setObjectID(null);
                    setAdd(<Button id="Add" name="Add" label="Add Stratum" type="button" icon="pi pi-plus"  style={{ width: 'fit-contents', verticalAlign: 'center', fontSize: 'x-small', height: 40, backgroundColor: '#fff', color: '#000', borderRadius: 'var(--border-radius)' }} className="flex align-self-center" size="small" raised onClick={()=>addStratum(HorizontalPattern[0]["horizontal_pattern_id"])} />);
                    setResets(resetter);
                    break;
                case 6:
                    setHideForm(false);
                    setLCType('Strata');
                    setDelete(<Button id="Delete" name="Delete" label="Delete Stratum" type="button" icon="pi pi-trash"  style={{ width: 'fit-contents', verticalAlign: 'center', fontSize: 'x-small', height: 40, backgroundColor: '#aaa', color: '#fff', borderRadius: 'var(--border-radius)' }} className="flex align-self-center" size="small" raised onClick={()=>setConfirmStratumDelete(true)} />);
                    setAdd(null);
                    setDisplayForm(displayFormElements(legend["LC_Patterns"][1]["elements"]));
                    setElementID(elementKey[elementKey.length-2]);
                    let Stratum = LC_Objectfilter(LC_Strata.current, "stratumID", elementKey[elementKey.length-1]);
                    if(Stratum.length > 0){
                        legend["LC_Patterns"][1]["elements"].map((element)=>{
                            if(typeof(Stratum[0][element['element_name']]) === "undefined" || Stratum[0][element['element_name']] === null || Stratum[0][element['element_name']] === "")
                                resetter.push('setValue("'+element['element_name']+'", "")');
                            else if(typeof(Stratum[0][element['element_name']]) === "object")
                                resetter.push('setValue("'+element['element_name']+'", ['+Stratum[0][element['element_name']][0]+','+Stratum[0][element['element_name']][1]+'])');
                            else if(typeof(Stratum[0][element['element_name']]) === "number")
                                resetter.push('setValue("'+element['element_name']+'", '+Stratum[0][element['element_name']]+')');
                            else
                                resetter.push('setValue("'+element['element_name']+'", "'+Stratum[0][element['element_name']]+'")');
                        }); 
                        resetter.push('setValue("objectID", '+Stratum[0]["objectID"]+')');
                        resetter.push('setValue("formElementID", '+Stratum[0]["stratumID"]+')'); 
                        resetter.push('setValue("elementID", '+elementKey[elementKey.length-2]+')');
                        setFormElementID(Stratum[0]["stratumID"]);
                        setObjectID(Stratum[0]["objectID"]);
                    }
                    else
                        setObjectID(null);
                    setResets(resetter);
                    break;
                case 7:
                    setHideForm(false);                    
                    setLCType('Properties');
                    setDelete(<Button id="Delete" name="Delete" label="Delete Element Attributes" type="button" icon="pi pi-trash"  style={{ width: 'fit-contents', verticalAlign: 'center', fontSize: 'x-small', height: 40, backgroundColor: '#aaa', color: '#fff', borderRadius: 'var(--border-radius)' }} className="flex align-self-center" size="small" raised onClick={()=>setConfirmPropertyDelete(true)} />);
                    setAdd(null);
                    let activBlock = blocks.current["LC_Block"]?.filter( block => block.block_id === parseInt(elementKey[elementKey.length-1]) );
                    setDisplayForm(displayFormElements(activBlock[0]["elements"]));
                    setElementID(elementKey[elementKey.length-2]);                    
                    activBlock[0].elements.map((element)=>{
                        display.push('document.getElementById( "'+element['element_name']+'" ).style.display = "'+element['display_default']+'"');
                        let prevValue = undefined;
                        if(LC_Properties.current.length > 0){
                            let relevantProperty = LC_Properties.current.filter(property => property.StratumID === parseInt(elementKey[elementKey.length-2]) && property.BlockID === parseInt(elementKey[elementKey.length-1]));                            
                            if(relevantProperty[0] !== undefined)
                                prevValue = relevantProperty[0][element['element_name']];                            
                        }                       
                        if(typeof(prevValue) === "undefined")
                            resetter.push('setValue("'+element['element_name']+'", "")');                        
                        else if(typeof(prevValue) === "object")
                            resetter.push('setValue("'+element['element_name']+'", ['+prevValue[0]+','+prevValue[1]+'])');
                        else if(typeof(prevValue) === "number")
                            resetter.push('setValue("'+element['element_name']+'", '+prevValue+')');
                        else
                            resetter.push('setValue("'+element['element_name']+'", "'+prevValue+'")');                        
                        if(element['element_type'] == 'Dropdown' && prevValue != '')
                            reconfig.push('formReconfig("'+element['element_rules']['options_name']+'","'+prevValue+'")');
                    });
                    resetter.push('setValue("objectID", '+elementKey[elementKey.length-1]+')');
                    resetter.push('setValue("formElementID", "'+elementKey[elementKey.length-2]+'")');
                    resetter.push('setValue("elementID", '+elementKey[elementKey.length-2]+')');                    
                    setFormElementID(elementKey[elementKey.length-2]);
                    setObjectID(elementKey[elementKey.length-1]);
                    setResets(resetter);
                    displays.current = display;
                    reconfigs.current = reconfig;
                    break;
                default:
                    setHideForm(true);setDelete(null);
            }
        }
        /*console.log(rerenderTrigger);
        const timer = setTimeout(() => {
            console.log(rerenderTrigger);
            reset();
        }, 500);*/
    },[activeLCElement,rerenderTrigger]);

    const formReset = ()=>{
        resets?.map((formElement)=>{            
            const timer = setTimeout(() => {                
                eval(formElement);
            }, 20);
        });
    }
    const formDefault = ()=>{        
        if(displays.current.length > 0){
            displays.current?.map((displayCondition)=>{
                if(displayCondition){
                    const timer = setTimeout(() => {
                        eval(displayCondition);
                    }, 20);
                }
            });
        }
        displays.current = [];
    }
    const formReconfig = (element,value)=>{
        let option = options.current["LC_Options"]?.filter( selectedOptions => selectedOptions.option_name === element );
        let optionDetails = option[0].options.find(o => o.label === value);
        if(optionDetails!=undefined){
            let show = optionDetails['show']?.split(',');
            show?.map((element)=>{            
                const timer = setTimeout(() => {                
                    eval('document.getElementById( "'+element.trim()+'" ).style.display = ""');
                }, 20);
            });
            let hide = optionDetails['hide']?.split(',');
            hide?.map((element)=>{            
                const timer = setTimeout(() => {
                    eval('document.getElementById( "'+element.trim()+'" ).style.display = "none"');
                }, 20);
            });
        }
    }
    const formReconfigExe = ()=>{
        if(reconfigs.current?.length > 0){
            reconfigs.current?.map((displayCondition)=>{
                if(displayCondition){
                    const timer = setTimeout(() => {
                        eval(displayCondition);
                    }, 20);
                }
            });
        }
        reconfigs.current = [];
    }

    const [confirmClassDelete, setConfirmClassDelete] = useState<boolean>(false);
    const [confirmHPDelete, setConfirmHPDelete] = useState<boolean>(false);
    const [confirmStratumDelete, setConfirmStratumDelete] = useState<boolean>(false);
    const [confirmPropertyDelete, setConfirmPropertyDelete] = useState<boolean>(false);
    const acceptClassDelete = (formElementID) => {
        let deletd_Class = LC_Class.current.filter(Clss => Clss.class_id === formElementID);
        deleteClass(formElementID);
        toast.current?.show({ severity: 'warn', summary: 'Confirmed', detail: 'Class '+deletd_Class[0].name+' deleted!', life: 3000 });
    }
    const acceptHPDelete = (formElementID) => {
        let deletd_HP = LC_HorizontalPatterns.current.filter(HP => HP.horizontal_pattern_id === formElementID);
        deleteHorizontalPattern(formElementID);
        toast.current?.show({ severity: 'warn', summary: 'Confirmed', detail: 'Horizontal Pattern '+deletd_HP[0].name+' deleted!', life: 3000 });
    }
    const acceptStratumDelete = (formElementID) => {
        let deletd_Stratum = LC_Strata.current.filter(stratum => stratum.stratumID === formElementID);
        deleteStratum(formElementID);
        toast.current?.show({ severity: 'warn', summary: 'Confirmed', detail: 'Stratum '+deletd_Stratum[0].name+' deleted!', life: 3000 });
    }
    const acceptPropertyDelete = (elementID,formElementID,objectID) => {
        let deletd_Element = blocks.current["LC_Block"].filter(blocks => blocks.block_id === parseInt(objectID));       
        deleteProperty(formElementID,objectID);
        toast.current?.show({ severity: 'warn', summary: 'Confirmed', detail: 'Element '+deletd_Element[0].block_name+' deleted!', life: 3000 });
    }
    const reject = () => {
        toast.current?.show({ severity: 'info', summary: 'Cancelled', detail: 'Action Cancelled', life: 3000 });
    }

    const d = new Date();
    const deleteClass = (class_id)=> {   
        //console.log("Classes Start", LC_Class.current);     
        let undeletd_Classes = LC_Class.current.filter(Clss => Clss.class_id !== class_id);
        LC_Class.current = [...(undeletd_Classes || [])];        

        if(Object.keys(LC_ClassCharacteristics.current).length > 0){            
            delete LC_ClassCharacteristics.current[class_id];                       
        }        
        let deletd_HPs = LC_HorizontalPatterns.current.filter(HPs => HPs.class_id === class_id);        
        if(deletd_HPs !== undefined){
            deletd_HPs.map((deletd_HP)=>{
                deleteHorizontalPattern(deletd_HP.horizontal_pattern_id);
            });
        }
        setRerenderTrigger('Deleted Class'+classesNumber.current+d.getTime());
        setActiveLCElement(null);
        //console.log("Classes End", LC_Class.current);
    }
    const deleteHorizontalPattern = (HPID)=>{
        //console.log("HP Start",LC_HorizontalPatterns.current);
        let deletd_strata = LC_Strata.current.filter(stratum => stratum.HPID === HPID);
        //console.log("Stratum to Delete",deletd_strata);
        if(deletd_strata !== undefined){            
            deletd_strata.map((deletd_stratum)=>{
                deleteStratum(deletd_stratum.stratumID);
            });
        }

        let undeletd_HPs = LC_HorizontalPatterns.current.filter(HP => HP.horizontal_pattern_id !== HPID);        
        LC_HorizontalPatterns.current = [...(undeletd_HPs || [])];        
        horizontalPatternNumber.current = horizontalPatternNumber.current+1;
        setRerenderTrigger('Deleted Horizontal Pattern'+horizontalPatternNumber.current+d.getTime());
        setActiveLCElement(null);
        //console.log("HP End",LC_HorizontalPatterns.current);
    }
    const deleteStratum = (StratumID)=>{
        //console.log("Strata Start",LC_Strata.current);
        //console.log("Property Start", LC_Properties.current);
        let undeletd_properties = LC_Properties.current.filter(properties => properties.StratumID !== StratumID);
        //console.log("Properties to Keep", undeletd_properties);
        if(undeletd_properties !== undefined){
            LC_Properties.current = [...(undeletd_properties || [])];
            stratumPropertyNumber.current = undeletd_properties.length+1;
        }
        /*console.log("Property End", LC_Properties.current);
        console.log("Characteristics Start", LC_Characteristics.current);*/
        let undeletd_characteristics = LC_Characteristics.current.filter(characteristics => characteristics.StratumID !== StratumID);
        //console.log("Xteristics to Keep", undeletd_characteristics);
        if(undeletd_characteristics !== undefined){
            LC_Characteristics.current = [...(undeletd_characteristics || [])];
            stratumCharacteristicNumber.current = undeletd_characteristics.length+1;
        }
        //console.log("Characteristics End", LC_Characteristics.current);

        let undeletd_strata = LC_Strata.current.filter(stratum => stratum.stratumID !== StratumID);        
        LC_Strata.current = [...(undeletd_strata || [])];
        strataNumber.current = strataNumber.current+1;
        setRerenderTrigger('Deleted Strata'+strataNumber.current+d.getTime());
        setActiveLCElement(null);
        //console.log("Strata End",LC_Strata.current);
    }
    const deleteProperty = (StratumID,BlockID)=>{
        //console.log("Property Start", LC_Properties.current);
        let undeletd_properties = LC_Properties.current.filter(properties => ((properties.StratumID !== parseInt(StratumID)) || (properties.StratumID === parseInt(StratumID) && properties.BlockID !== parseInt(BlockID))));
        let deletd_characteristics = LC_Characteristics.current.filter(characteristics => characteristics.StratumID === parseInt(StratumID) && characteristics.BlockID === parseInt(BlockID));
        //console.log("Xteristics to Delete", deletd_characteristics);
        if(deletd_characteristics !== undefined){            
            deletd_characteristics.map((deletd_characteristic)=>{
                deleteCharacteristic(deletd_characteristic.StratumID,deletd_characteristic.BlockID,deletd_characteristic.CharacteristicID);
            });
        }       
        LC_Properties.current = [...(undeletd_properties || [])];
        stratumPropertyNumber.current = undeletd_properties.length+1;
        setRerenderTrigger('Deleted Property'+stratumPropertyNumber.current+d.getTime());
        setActiveLCElement(null);
        //console.log("Property End", LC_Properties.current);
    }
    const deleteCharacteristic = (StratumID,BlockID,CharacteristicID)=>{
        //console.log("XTeristic Start", LC_Characteristics.current);
        let undeletd_characteristics = LC_Characteristics.current.filter(characteristics => ((characteristics.StratumID !== parseInt(StratumID) || characteristics.BlockID !== parseInt(BlockID)) || (characteristics.StratumID === parseInt(StratumID) && characteristics.BlockID === parseInt(BlockID) && characteristics.CharacteristicID !== parseInt(CharacteristicID))));
        LC_Characteristics.current = [...(undeletd_characteristics || [])];
        stratumCharacteristicNumber.current = undeletd_characteristics.length+1;
        setRerenderTrigger('Deleted Characteristic'+stratumCharacteristicNumber.current+d.getTime());
        setActiveLCElement(null);
        //console.log("XTeristic End", LC_Characteristics.current);
    }

    const addClass = ()=>{
        /*console.log("Classes Start",LC_Class.current);
        console.log("Class Number Start",classesNumber.current);
        console.log("HPs Start",LC_HorizontalPatterns.current);
        console.log("Strata Start",LC_Strata.current);
        console.log("Properties Start",LC_Properties.current);
        console.log("Characteristics Start",LC_Characteristics.current);*/
        classesNumber.current = classesNumber.current+1;
        LC_Class.current = [...LC_Class.current, {legend_id: LC_Legend.current.id, class_id: classesNumber.current, class_name: 'Class '+classesNumber.current, class_description: 'The land characterization class', class_map_code: 'LCR'+classesNumber.current, class_color_code: 'FF0000'}];
        setRerenderTrigger('Added Class'+classesNumber.current+d.getTime());        
        /*console.log("Classes End",LC_Class.current);
        console.log("Class Number End",classesNumber.current);
        console.log("HPs End",LC_HorizontalPatterns.current);
        console.log("Strata End",LC_Strata.current);
        console.log("Properties End",LC_Properties.current);
        console.log("Characteristics End",LC_Characteristics.current);*/
    }
    const addHorizontalPattern = (class_id)=>{
        /*console.log("Classes Start",LC_Class.current);
        console.log("HP Number Start",horizontalPatternNumber.current);
        console.log("HPs Start",LC_HorizontalPatterns.current);
        console.log("Strata Start",LC_Strata.current);
        console.log("Properties Start",LC_Properties.current);
        console.log("Characteristics Start",LC_Characteristics.current);*/
        LC_HorizontalPatterns.current = [...LC_HorizontalPatterns.current, { class_id: parseInt(class_id), horizontal_pattern_id: horizontalPatternNumber.current, name: 'Horizontal Pattern '+horizontalPatternNumber.current, description: 'The class horizontal pattern', cover: [0,100], occurrence: [0,100], type: null }];
        setRerenderTrigger('Added Horizontal Pattern'+horizontalPatternNumber.current+d.getTime());
        horizontalPatternNumber.current = horizontalPatternNumber.current+1;
        /*console.log("Classes End",LC_Class.current);
        console.log("HP Number End",horizontalPatternNumber.current);
        console.log("HPs End",LC_HorizontalPatterns.current);
        console.log("Strata End",LC_Strata.current);
        console.log("Properties End",LC_Properties.current);
        console.log("Characteristics End",LC_Characteristics.current);*/
    }
    const addStratum = (horizontal_pattern_id)=>{
        LC_Strata.current = [...LC_Strata.current, { HPID: parseInt(horizontal_pattern_id), stratumID: strataNumber.current, name: 'Stratum '+strataNumber.current, description: 'The Horizontal Pattern layer', presenceType: 'Fixed', portioning: [0,100], onTop: null, onTopType: null }];
        setRerenderTrigger('Added Stratum'+strataNumber.current+d.getTime());
        strataNumber.current = strataNumber.current+1;
    }

    

    return (
        <>
        {activeLCElement &&
            <div className="card flex flex-column gap-1 text-xs p-2 m-0 w-full" style={{ maxHeight: '200px' }}>
                <Toast ref={toast} />
                <ConfirmDialog visible={confirmClassDelete} onHide={() => setConfirmClassDelete(false)} message="Are you sure you want to delete the Class? This will delete all Horizontal Patterns, Strata, Elements, Properties and Characteristics associated with it." header="Delete Class Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={()=>acceptClassDelete(formElementID)} reject={reject} />
                <ConfirmDialog visible={confirmHPDelete} onHide={() => setConfirmHPDelete(false)} message="Are you sure you want to delete the Horizontal Pattern? This will delete all Strata, Elements, Properties and Characteristics associated with it." header="Delete Horizontal Pattern Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={()=>acceptHPDelete(formElementID)} reject={reject} />
                <ConfirmDialog visible={confirmStratumDelete} onHide={() => setConfirmStratumDelete(false)} message="Are you sure you want to delete the Stratum? This will delete all Elements, Properties and Characteristics associated with it." header="Delete Stratum Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={()=>acceptStratumDelete(formElementID)} reject={reject} />
                <ConfirmDialog visible={confirmPropertyDelete} onHide={() => setConfirmPropertyDelete(false)} message="Are you sure you want to delete the Property? This will delete the Element and Characteristics associated with it." header="Delete Property & Element Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={()=>acceptPropertyDelete(elementID,formElementID,objectID)} reject={reject} />
                <form key={LCType+d.getTime()} id={LCType+d.getTime()} name={LCType+d.getTime()} onSubmit={handleSubmit(submitProperty)} className="flex flex-column justify-contents-center">
                    <Controller
                    name="LCType"                                 
                    control={control}
                    render={({ field, fieldState }) => (
                        <input type="hidden" id={field.name} name={field.name} value={LCType} />
                        )}
                    />
                    <Controller
                    name="elementID"
                    control={control}
                    render={({ field, fieldState }) => (
                        <input type="hidden" id={field.name} name={field.name} value={elementID} />
                        )}
                    />
                    <Controller
                    name="formElementID"
                    control={control}
                    render={({ field, fieldState }) => (
                        <input type="hidden" id={field.name} name={field.name} value={formElementID} />
                        )}
                    />
                    <Controller
                    name="objectID"
                    control={control}
                    render={({ field, fieldState }) => (
                        <input type="hidden" id={field.name} name={field.name} value={objectID} />
                        )}
                    />
                    {!hideForm && <>
                    {displayForm}
                    {formReset()}
                    {formDefault()}
                    {formReconfigExe()}                    
                    <div className="flex flex-row m-1 p-0" style={{ width: '100%'}}>
                        <div className="flex align-items-center justify-content-center" style={{ width: '25%' }}>                            
                            {Add}
                        </div>
                        <div className="flex align-items-center justify-content-center" style={{ width: '50%' }}>
                            <Button id="Property" name="Property" label="Save Properties" type="submit" icon="pi pi-check"  style={{ width: '60%', verticalAlign: 'center', fontSize: 'x-small', height: 20, backgroundColor: 'var(--blue-100)', color: 'var(--error-100)', borderRadius: 'var(--border-radius)' }} className="flex align-self-center" size="small" raised />
                        </div>
                        <div className="flex align-items-center justify-content-center" style={{ width: '25%' }}>                            
                            {Delete}
                        </div>
                    </div>
                    </>}             
                </form>
            </div>
        }        
        </>
    );
}
