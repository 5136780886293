
import React, { useEffect, useState, useRef } from 'react';
import { DataTable, DataTableRowEditCompleteEvent } from 'primereact/datatable';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

export const Connector = (props) => {
    const {UUID,
        translator,
        legendTemplate,
        options,        
        blocks,
        blockLookUp,
        characteristics,
        characteristicLookUp,
        referenceLegend,
        setReferenceLegend,
        fileContent,
        setLegendUploadVisible,
        setConnectorVisible
        } = props;

    let reference = Object.entries(fileContent[0]);
    fileContent.forEach((file,index)=>{
        let filename = Object.keys(file);
        if (/reference/i.test(filename[0]))
            reference = Object.entries(fileContent[index]);
    });
    let baseHeaderTitle = reference[0][0];
    let baseHeaderClasses = reference[0][1]['LCT_Class'];
    let baseHPs = reference[0][1]['LCT_HorizontalPatterns'];
    let baseStrata = reference[0][1]['LCT_Strata'];
    let baseProperties = reference[0][1]['LCT_Properties'];
    let baseElements = {};
    let rowColumns = [];
    let colHeader = [];

    let referenceClasses = [];
    baseHeaderClasses.map((clss)=>{
        referenceClasses.push(clss['class_name']);
        colHeader.push(<Column header={clss['class_name']} colSpan={2} />);
        rowColumns.push(<Column key={"E"+clss['class_id']} header={"Matching Elements"} sortable filter field={"ClassElements"+clss['class_id']} />);
        rowColumns.push(<Column key={"P"+clss['class_id']} header={"Similarity"} field={"ClassPercentage"+clss['class_id']} />);
        let elements = [];
        let filterd_HPs = baseHPs.filter( HP => HP.class_id === clss['class_id'] );
        filterd_HPs.map((HP)=>{
            let filterd_Strata = baseStrata.filter( Strata => Strata.HPID === HP['horizontal_pattern_id'] );
            filterd_Strata.map((Strata)=>{
                let filterd_Properties = baseProperties.filter( Properties => Properties.StratumID === Strata['stratumID'] );
                filterd_Properties.map((Properties)=>{
                    elements.push(Properties['BlockID']);
                });
            });
        });
        baseElements = {...baseElements, [clss['class_id']]: elements};
    });
    
    let appointedClasses = [];
    let legend = [];
    fileContent.map((file)=>{
        let filename = Object.keys(file);
        if (!/reference/i.test(filename[0])){
            let subject = Object.entries(file);            
            appointedClasses.push(subject[0][1]['LCT_Class']);
            legend.push(subject);
        }
    });

    console.log(legend);

    let connectionRules = [
        {
            inclusive: [
                {
                    id: 1,
                    name: "Antonio 1",
                    priority: 1,
                    rules: {
                        elements: {
                            id: 1,
                            priority: 1,
                            element: "LC_Trees",
                            rules: {
                                characteristics: [
                                    {
                                        id: 1,
                                        name: "Species Test",
                                        priority: 1,
                                        rules: [
                                            { object: "LC_Species", lower_bound: "Apple", lower_tolerance: "", upper_bound: "Apple", upper_tolerance: "", condition: "equals" }
                                        ]
                                    }
                                ],
                                properties: [
                                    {
                                        id: 1,
                                        name: "Cover & Height Test",
                                        priority: 1,
                                        rules: [
                                            { object: "LC_Cover", presence: "required", lower_bound: "0", lower_tolerance: "10", upper_bound: "40", upper_tolerance: "10", condition: "between" },
                                            { object: "LC_Height", presence: "optional", lower_bound: "1.5", lower_tolerance: "0.5", upper_bound: "40", upper_tolerance: "10", condition: "between" }
                                        ]
                                    },
                                    {
                                        id: 2,
                                        name: "Depth Test",
                                        priority: 2,
                                        rules: [
                                            { object: "LC_Depth", presence: "optional", lower_bound: "-3.5", lower_tolerance: "-0.5", upper_bound: "-1", upper_tolerance: "-0.5", condition: "between" }
                                        ]
                                    },
                                ]
                            }
                        },
                        strata: {
                            id: 1,
                            priority: 1,
                            rules: [
                                { object: "1", presence: "required" }
                            ]
                        },
                        horizontalPatterns: {
                            id: 1,
                            priority: 1,
                            rules: [
                                { 
                                    object: "1", 
                                    presence: "required", 
                                    cover: { lower_bound: "-3.5", lower_tolerance: "-0.5", upper_bound: "-1", upper_tolerance: "-0.5", condition: "between" },
                                    occurrence: { lower_bound: "-3.5", lower_tolerance: "-0.5", upper_bound: "-1", upper_tolerance: "-0.5", condition: "between" }
                                }
                            ]
                        },
                        classes: {
                            id: 1,
                            priority: 1,
                            rules: [
                                { 
                                    object: "1", 
                                    presence: "required", 
                                    map_code: { lower_bound: "*", lower_tolerance: "*", upper_bound: "*", upper_tolerance: "*", condition: "any" }
                                }
                            ]
                        }
                    }
                }
            ],
            exclusive: [
                {
                    id: 1,
                    name: "Antonio 1",
                    priority: 1,
                    rules: {
                        elements: {
                            id: 1,
                            priority: 1,
                            element: "LC_Trees",
                            rules: {
                                characteristics: [
                                    {
                                        id: 1,
                                        name: "Species Test",
                                        priority: 1,
                                        rules: [
                                            { object: "LC_Species", lower_bound: "Apple", lower_tolerance: "", upper_bound: "Apple", upper_tolerance: "", condition: "equals" }
                                        ]
                                    }
                                ],
                                properties: [
                                    {
                                        id: 1,
                                        name: "Cover & Height Test",
                                        priority: 1,
                                        rules: [
                                            { object: "LC_Cover", presence: "required", lower_bound: "0", lower_tolerance: "10", upper_bound: "40", upper_tolerance: "10", condition: "between" },
                                            { object: "LC_Height", presence: "optional", lower_bound: "1.5", lower_tolerance: "0.5", upper_bound: "40", upper_tolerance: "10", condition: "between" }
                                        ]
                                    },
                                    {
                                        id: 2,
                                        name: "Depth Test",
                                        priority: 2,
                                        rules: [
                                            { object: "LC_Depth", presence: "optional", lower_bound: "-3.5", lower_tolerance: "-0.5", upper_bound: "-1", upper_tolerance: "-0.5", condition: "between" }
                                        ]
                                    },
                                ]
                            }
                        },
                        strata: {
                            id: 1,
                            priority: 1,
                            rules: [
                                { object: "1", presence: "required" }
                            ]
                        },
                        horizontalPatterns: {
                            id: 1,
                            priority: 1,
                            rules: [
                                { 
                                    object: "1", 
                                    presence: "required", 
                                    cover: { lower_bound: "-3.5", lower_tolerance: "-0.5", upper_bound: "-1", upper_tolerance: "-0.5", condition: "between" },
                                    occurrence: { lower_bound: "-3.5", lower_tolerance: "-0.5", upper_bound: "-1", upper_tolerance: "-0.5", condition: "between" }
                                }
                            ]
                        },
                        classes: {
                            id: 1,
                            priority: 1,
                            rules: [
                                { 
                                    object: "1", 
                                    presence: "required", 
                                    map_code: { lower_bound: "*", lower_tolerance: "*", upper_bound: "*", upper_tolerance: "*", condition: "any" }
                                }
                            ]
                        }
                    }
                }
            ]
        }
    ]

    const dt = useRef(null);
    const [products, setProducts] = useState<Product[]>(appointedClasses[0]);
    const [statuses] = useState<string[]>(['INSTOCK', 'LOWSTOCK', 'OUTOFSTOCK']);

    const getSeverity = (value: string) => {
        switch (value) {
            case 'Artificial surfaces':
                return 'success';

            case 'Herbaceous crops':
                return 'warning';

            case 'Woody crops':
                return 'danger';

            default:
                return null;
        }
    };

    const onRowEditComplete = (e: DataTableRowEditCompleteEvent) => {
        let _products = [...products];
        let { newData, index } = e;

        _products[index] = newData as Product;

        setProducts(_products);
    };

    const textEditor = (options: ColumnEditorOptions) => {
        return <InputText type="text" value={options.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback!(e.target.value)} />;
    };

    const statusEditor = (options: ColumnEditorOptions) => {
        return (
            <Dropdown
                value={options.value}
                options={referenceClasses}
                onChange={(e: DropdownChangeEvent) => options.editorCallback!(e.value)}
                placeholder="Select a Reference Class"
                itemTemplate={(option) => {
                    return <Tag value={option} severity={getSeverity(option)}></Tag>;
                }}
            />
        );
    };

    const priceEditor = (options: ColumnEditorOptions) => {
        return <InputNumber value={options.value}  onValueChange={(e: InputNumberValueChangeEvent) => options.editorCallback!(e.value)} mode="currency" currency="USD" locale="en-US" />;
    };

    const statusBodyTemplate = (rowData: Product) => {
        return <Tag value={rowData.inventoryStatus} severity={getSeverity(rowData.inventoryStatus)}></Tag>;
    };

    const priceBodyTemplate = (rowData: Product) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.price);
    };

    const allowEdit = (rowData: Product) => {
        return rowData.name !== 'Blue Band';
    };

    interface Product {
        id: string;
        code: string;
        name: string;
        description: string;
        image: string;
        price: number;
        category: string;
        quantity: number;
        inventoryStatus: string;
        rating: number;
    }
    
    interface ColumnMeta {
        field: string;
        header: string;
    }

    const cols: ColumnMeta[] = [
        { field: 'code', header: 'Code' },
        { field: 'name', header: 'Name' },
        { field: 'category', header: 'Category' },
        { field: 'quantity', header: 'Quantity' }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    /*const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, products);
                doc.save('products.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(products);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'products');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };*/

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
            
        </div>
    );
    return (
        <>
            <div className="card m-0 p-0" style={{ height: '4vh', minHeight: '20px' }}>
                <img alt="Land CHaracterization Software" src="logo.png" height="30" className="mr-2"></img>            
                <img alt="Food and Agriculture Organization of the United Nations" src="fao-logo.png" height="30" className="mr-2"></img>
            </div>
            <div className="card flex justify-content-center">
                <Button label="Back" icon="pi pi-step-backward-alt" size="small" onClick={()=>{setLegendUploadVisible(true);setConnectorVisible(false);}} style={{ backgroundColor: 'var(--highlight-bg)', color: 'var(--error-100)', borderRadius: 'var(--border-radius)' }}  className="mr-2 text-xs p-1 m-1" />
            </div>
            <div className="card flex flex-row gap-2 m-1 p-1">                
                <div className="card flex flex-column justify-content-center align-self-start" style={{ width: '60%' }}>
                    <Card title="Legend Allocation" className="w-full" >
                        <div className="card p-fluid">
                            <DataTable ref={dt} value={products} footer={header} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} tableStyle={{ minWidth: '50rem' }}>
                                <Column field="class_map_code" header="Appointed Legend Class Code" style={{ width: '20%' }}></Column>
                                <Column field="class_name" header="Appointed Legend Class" style={{ width: '20%' }}></Column>
                                <Column field="reference_class" header="Matching Rules" style={{ width: '20%' }}></Column>
                                <Column field="inventoryStatus" header={"Reference "+referenceLegend.name+" Class"} body={statusBodyTemplate} editor={(options) => statusEditor(options)} style={{ width: '20%' }}></Column>                                
                                <Column rowEditor={allowEdit} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                            </DataTable>
                        </div>
                    </Card>
                </div>
                <div className="card flex flex-column justify-content-center align-self-start" style={{ width: '40%' }}>
                    <Card title="Resources" className="w-full" style={{ background: '#eee' }}>                    
                        <table>
                            <tbody>
                                <tr>
                                    <td width='200px' valign='top'><b>Allocation Report:</b></td>
                                    <td><a href="#">Legend to {referenceLegend.name}.pdf</a></td>
                                </tr>
                                <tr>
                                    <td width='200px' valign='top'><b>GIS Lookup:</b></td>
                                    <td><a href="#">Legend to {referenceLegend.name}.json</a></td>
                                </tr>
                                <tr>
                                    <td width='200px' valign='top'><b>Reference Rules:</b></td>
                                    <td><b>Current Reference Legends</b><br />
                                        <ul>
                                            <li><a href="#">Intergovernmental Panel on Climate Change (IPCC)</a></li>
                                            <li><a href="#">System of Environmental Economic Accounting (SEEA)</a></li>
                                            <li><a href="#">Moderate Resolution Imaging Spectroradiometer (MODIS 250m)</a></li>
                                            <li><a href="#">MERIS 350m</a></li>
                                            <li><a href="#">European Space Agency 10m (ESA 10m)</a></li>
                                            <li><a href="#">European Space Agency 10m (ESA 20m)</a></li>
                                            <li><a href="#">Chinese cover 30m</a></li>
                                            <li><a href="#">Globcover (FAO)</a></li>
                                            <li><a href="#">CORINE Land Cover (CLC)</a></li>
                                            <li><a href="#">Anderson</a></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>                    
                </div>
            </div>
        </>
    );
}