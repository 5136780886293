import React, { useState,useRef,useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Button } from "primereact/button";
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel, TabViewTabChangeEvent } from 'primereact/tabview';
import { Slider, SliderChangeEvent } from "primereact/slider";
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Chip } from 'primereact/chip';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import randomstring from "randomstring";

import "./tabview.css";

export const GuideLegendCreation3 = (props:any) => {
    const {UUID,
            setRerenderTrigger,          
            setGuide2Visible,
            setGuide3Visible,
            setGuide4Visible,
            LC_Class,
            LC_HorizontalPatterns,
            LC_Strata,
            horizontalPatternNumber,
            strataNumber,
            LC_Properties,
            stratumPropertyNumber,
            LC_Characteristics,
            stratumCharacteristicNumber,
            OptionsBus
        } = props;

    let classes = [];
    LC_Class.current.map((clss)=>{
        classes.push({class_id: clss.class_id, class_name: clss.class_name});
    });
    const [HorizontalPatternID, setHPID] = useState(horizontalPatternNumber.current);
    const [HPName, setHPName] = useState<string>('Horizontal Pattern '+horizontalPatternNumber.current);
    const [HPDescription, setHPDescription] = useState<string>('The class horizontal pattern');
    const [HPCover, setHPCover] = useState<number[]>([0,100]);
    const [HPOccurrence, setHPOccurrence] = useState<number[]>([0,100]);
    const [HPType, setHPType] = useState<string>('');
    const [horizontalPatterns, addHorizontalPattern] = useState(LC_HorizontalPatterns.current);
    const [strata, addStratum] = useState(LC_Strata.current);
    const [StratumID, setStratumID] = useState(strataNumber.current);
    
    const toast = useRef(null);

    const showHorizontalPattern = () => {  
        toast.current.show({ severity: 'success', summary: 'Class Saved', detail: "Class: "+getValues("clss.class_name") });
        toast.current.show({ severity: 'success', summary: 'Horizontal Pattern Added', content: () => (
                    <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                        <p>Name: {getValues('horizontal_pattern_name')}<br/>
                           Description: {getValues('horizontal_pattern_description')}<br/>
                           Cover: {getValues('horizontal_pattern_cover')}<br/>
                           Occurrence: {getValues('horizontal_pattern_occurrence')}</p>
                    </div>
                ) });
        toast.current.show({ severity: 'success', summary: 'Type Saved', detail: "Type: "+getValues('horizontal_pattern_type') });
    };
    const showStratum = (stratum) => {
        toast.current.show({ severity: 'success', summary: 'Stratum Saved', detail: "Stratum: "+stratum });        
    };

    let defaultValues = {
        clss: null,
        horizontal_pattern_id: HorizontalPatternID,
        horizontal_pattern_name: HPName,
        horizontal_pattern_description: HPDescription,
        horizontal_pattern_cover: HPCover,
        horizontal_pattern_occurrence: HPOccurrence,
        horizontal_pattern_type: HPType,
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        reset
    } = useForm({ defaultValues });
    const {
        control: control1,
        formState: { errors: errors1 },
        handleSubmit: handleSubmit1,
        setValue: setValue1,
        reset: reset1
    } = useForm({  });

    const submitHorizontalPattern = (data: any,event) => {
        event.preventDefault();
        let HPID = (document.getElementById("horizontal_pattern_id") as HTMLInputElement).value;
        addHorizontalPattern([...(horizontalPatterns || []), { class_id: parseInt(data.clss.class_id), horizontal_pattern_id: parseInt(HPID), name: data.horizontal_pattern_name, description: data.horizontal_pattern_description, cover: data.horizontal_pattern_cover, occurrence: data.horizontal_pattern_occurrence, type: data.horizontal_pattern_type  }]);
        showHorizontalPattern();
        setHPID(HorizontalPatternID+1);
        horizontalPatternNumber.current=HorizontalPatternID+1;
        reset();
    };
    const submitStratum = (data1: any,event) => {
        event.preventDefault();        
        let HPID = (document.getElementById("hp_ID-"+data1.stratum_id) as HTMLInputElement).value;
        addStratum([...(strata || []), { HPID: parseInt(HPID), stratumID: data1.stratum_id, name: data1['stratum_name-'+HPID], description: data1['stratum_description-'+HPID], presenceType: data1['stratum_presenceType-'+HPID].label, portioning: data1['stratum_portioning-'+HPID], onTop: data1['stratum_onTop-'+HPID].name, onTopType: data1['stratum_onTopType-'+HPID].label }]);
        showStratum(data1['stratum_name-'+HPID]);
        setStratumID(StratumID+1);
        strataNumber.current = StratumID+1;
        reset1();
    };
    
    const getFormErrorMessage = (name:any) => {
        return errors[name] ? <small className="p-error">This field is required</small> : <small className="p-error">&nbsp;</small>;
    };
    const getFormErrorMessage1 = (name:any) => {
        return errors1[name] ? <small className="p-error">This field is required</small> : <small className="p-error">&nbsp;</small>;
    };
    
    const bounds = [{
        type: "Percentage",
        min: 0,
        max: 100
    }];
    const returnBounds = (searchType: string)=> {
        let resultType = bounds.find((type) => type.type === searchType);
        return(
           resultType
        );
    }

    useEffect(() => {
            setValue1('stratum_id', StratumID);
    }, [StratumID]);

    const processStrata = ()=> {
        LC_HorizontalPatterns.current = horizontalPatterns;
        LC_Strata.current = strata;
        
        const classCheck = LC_Class.current?.filter(({ class_id: id1 }) => !horizontalPatterns?.some(({ class_id: id2 }) => id2 === id1));
        const stratumCheck = horizontalPatterns?.filter(({ horizontal_pattern_id: id1 }) => !strata?.some(({ HPID: id2 }) => id2 === id1));

        let HP = horizontalPatterns?.length;
        let str = strata?.length;        
        
        if(HP > 0 && stratumCheck?.length === 0 && classCheck?.length === 0){
            toast.current.show({ severity: 'success', summary: 'Strata Saved', detail: "Horizontal Patterns: "+HP+" Strata: "+str });
            const timer = setTimeout(() => {
                setGuide3Visible(false); 
                setGuide4Visible(true);
                setRerenderTrigger('Strata');
            }, 1000);            
        }
        else{
            toast.current.show({ severity: 'error', summary: 'Strata Error', detail: "Horizontal Patterns: "+HP+" Strata: "+str, life: 60000 });
            if(HP === 0)
                toast.current.show({ severity: 'error', summary: 'Strata Error', detail: "At least 1 Horizontal Pattern and 1 Stratum are required.", life: 60000 });
            if(stratumCheck?.length > 0)
                toast.current.show({ severity: 'error', summary: 'Strata Error', detail: "Stratum for "+stratumCheck?.map((pattern)=>(pattern.name))+" is undefined.", life: 60000 });
            if(classCheck?.length > 0)
                toast.current.show({ severity: 'error', summary: 'Horizontal Pattern Error', detail: "Horizontal Pattern for "+classCheck?.map((clss)=>(clss.class_name))+" is undefined.", life: 60000 });
        }
    }

    const returnStrata = (HPID)=>{
        let filtered = strata.filter(stratum => stratum.HPID === HPID);        
        return(filtered);
    }

    const [confirmHPDelete, setConfirmHPDelete] = useState<boolean>(false);    
    const [confirmStratumDelete, setConfirmStratumDelete] = useState<boolean>(false);
    const HPToDelete = useRef<number>(null);
    const promptHPDelete = (HPID)=>{        
        setConfirmHPDelete(true);
        HPToDelete.current = HPID;
    }
    const stratumToDelete = useRef<number>(null);
    const promptStratumDelete = (StratumID)=>{        
        setConfirmStratumDelete(true);
        stratumToDelete.current = StratumID;
    }    
    const acceptHPDelete = (index) => {        
        let HPName = horizontalPatterns[index].name
        deleteHorizontalPattern(index);        
        toast.current?.show({ severity: 'warn', summary: 'Confirmed', detail: 'Horizontal Pattern '+HPName+' deleted!', life: 3000 });
    }
    const acceptStratumDelete = (StratumID) => {
        let deletd_Stratum = LC_Strata.current.filter(stratum => stratum.stratumID === StratumID);
        deleteStratum(StratumID);
        toast.current?.show({ severity: 'warn', summary: 'Confirmed', detail: 'Stratum '+deletd_Stratum[0].name+' deleted!', life: 3000 });
    }    
    const reject = () => {
        toast.current?.show({ severity: 'info', summary: 'Cancelled', detail: 'Action Cancelled. Please click Back or close the wizard to refresh.', life: 3000 });
    }

    const deleteHorizontalPattern = (index)=>{
        /*console.log("HPs Start",LC_HorizontalPatterns.current);
        console.log("HP Number Start",horizontalPatternNumber.current);*/
        let HPID = horizontalPatterns[index]['horizontal_pattern_id'];
        
        let deletd_strata = strata.filter(stratum => stratum.HPID === HPID);
        //console.log("Stratum to Delete",deletd_strata);
        if(deletd_strata !== undefined){            
            deletd_strata.map((deletd_stratum)=>{
                deleteStratum(deletd_stratum.stratumID);
            });
        }

        let undeletd_HPs = horizontalPatterns.filter(HP => HP.horizontal_pattern_id !== HPID);
        addHorizontalPattern([...(undeletd_HPs || [])]);
        LC_HorizontalPatterns.current = [...(undeletd_HPs || [])];
        setHPID(HPID+1);
        horizontalPatternNumber.current = HPID+1;
        setRerenderTrigger('Deleted Horizontal Pattern'+horizontalPatternNumber.current+randomstring.generate(8));
        /*console.log("HPs End",LC_HorizontalPatterns.current);
        console.log("HP Number Start",horizontalPatternNumber.current);*/
    }
    const deleteStratum = (StratumID)=>{
        /*console.log("Strata Start",LC_Strata.current);
        console.log("Stratum Number Start",strataNumber.current);
        console.log("Property Start", LC_Properties.current);*/
        let undeletd_properties = LC_Properties.current.filter(properties => properties.StratumID !== StratumID);
        //console.log("Properties to Keep", undeletd_properties);
        if(undeletd_properties !== undefined){
            LC_Properties.current = [...(undeletd_properties || [])];
            stratumPropertyNumber.current = undeletd_properties.length+1;
        }
        /*console.log("Property End", LC_Properties.current);
        console.log("Characteristics Start", LC_Characteristics.current);*/
        let undeletd_characteristics = LC_Characteristics.current.filter(characteristics => characteristics.StratumID !== StratumID);
        //console.log("Xteristics to Keep", undeletd_characteristics);
        if(undeletd_characteristics !== undefined){
            LC_Characteristics.current = [...(undeletd_characteristics || [])];
            stratumCharacteristicNumber.current = undeletd_characteristics.length+1;
        }
        //console.log("Characteristics End", LC_Characteristics.current);

        let undeletd_strata = strata.filter(stratum => stratum.stratumID !== StratumID);
        addStratum([...(undeletd_strata || [])]);
        LC_Strata.current = [...(undeletd_strata || [])];
        setStratumID(StratumID+1);
        strataNumber.current = StratumID+1;
        setRerenderTrigger('Deleted Strata'+strataNumber.current+randomstring.generate(8));
        /*console.log("Strata End",LC_Strata.current);
        console.log("Stratum Number End",strataNumber.current);*/
    }

    const [displayClass_HP,setDisplayClass_HP] = useState<number>(null);

    return (
        <div className="card flex flex-column justify-content-center gap-1">            
            <Toast ref={toast} />
            <ConfirmDialog visible={confirmHPDelete} onHide={() => setConfirmHPDelete(false)} message="Are you sure you want to delete the Horizontal Pattern? This will delete all Strata, Elements, Properties and Characteristics associated with it." header="Delete Horizontal Pattern Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={()=>acceptHPDelete(HPToDelete.current)} reject={reject} />
            <ConfirmDialog visible={confirmStratumDelete} onHide={() => setConfirmStratumDelete(false)} message="Are you sure you want to delete the Stratum? This will delete all Elements, Properties and Characteristics associated with it." header="Delete Stratum Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={()=>acceptStratumDelete(stratumToDelete.current)} reject={reject} />
            <div className="card flex flex-row justify-content-center">
                <Card title="Step3: Strata" subTitle="Horizontal Patterns & Strata" className="w-full" style={{ width: '50%', background: '#eee' }}>
                    <div className="m-0">
                        Setup Horizontal Patterns and Strata
                    </div>
                </Card>
                <Divider layout="vertical" />                
                <div className="card align-contents-top w-full" style={{width: '50%' }}>
                    <form onSubmit={handleSubmit(submitHorizontalPattern)} className="flex flex-column gap-1">                        
                    <div className="w-full card justify-content-center p-0 m-0">
                        <div className="flex flex-row m-0 p-0">
                            <h4>Add Horizontal Pattern to:</h4>
                            <Controller
                                name="clss"
                                control={control}
                                rules={{ required: 'Class is required.' }}
                                render={({ field, fieldState }) => (
                                    <div className="card flex w-full p-inputgroup mt-3 pt-1">
                                        <span className="p-inputgroup-addon text-s p-0 m-0">
                                            <i className="pi pi-folder-open"></i>
                                        </span>
                                        <span className="p-float-label w-full">
                                            <Dropdown id={field.name} value={field.value} optionLabel="class_name" options={classes} focusInputRef={field.ref} onChange={(e) => {field.onChange(e.value);setDisplayClass_HP(e.value.class_id);}} />
                                            <label htmlFor={field.name}>Class</label>
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </div>
                                    
                                )}
                            />
                            {getFormErrorMessage('class_id')}
                        </div>
                        <Controller
                        name="horizontal_pattern_id"
                        defaultValue={HorizontalPatternID}
                        control={control}
                        rules={{ required: 'ID is required.' }}
                        render={({ field, fieldState }) => (
                            <input type="hidden" id={field.name} name={field.name} value={HorizontalPatternID} />
                            )}
                        />
                        <Controller
                        name="horizontal_pattern_name"
                        defaultValue={HPName}
                        control={control}
                        rules={{ required: 'Name is required.' }}
                        render={({ field, fieldState }) => (
                            <div className="card flex w-full p-inputgroup mt-3 pt-1">
                                <span className="p-inputgroup-addon text-s p-0 m-0">
                                    <i className="pi pi-pencil"></i>
                                </span>
                                <span className="p-float-label w-full">                        
                                    <InputText id={field.name} name={field.name} value={field.value} autoFocus className="p-inputtext-sm text-s  p-2 m-0" onChange={(e) => field.onChange(e.target.value)} style={{ width: '100%'}} />
                                    <label htmlFor={field.name}>Horizontal Pattern Name</label>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </div>
                            )}
                        />
                        <Controller
                        name="horizontal_pattern_description"
                        defaultValue={HPDescription}
                        control={control}
                        rules={{ required: 'Description is required.' }}
                        render={({ field, fieldState }) => (
                            <div className="card flex w-full p-inputgroup mt-3 pt-1">
                                <span className="p-inputgroup-addon text-s p-0 m-0">
                                    <i className="pi pi-pencil"></i>
                                </span>
                                <span className="p-float-label w-full">                        
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-s  p-2 m-0" onChange={(e) => field.onChange(e.target.value)} style={{ width: '100%'}} />
                                    <label htmlFor={field.name}>Description</label>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </div>
                            )}
                        />
                        <Controller
                        name="horizontal_pattern_cover"
                        defaultValue={HPCover}
                        control={control}
                        render={({ field, fieldState }) => (
                            <div className="card flex w-full p-inputgroup mt-3 pt-1">
                                <span className="p-inputgroup-addon text-xs p-2 m-0">Cover:</span>
                                    <div className="card flex flex-column w-full gap-0">
                                    <Slider id={field.name} name={field.name} value={field.value as any} onChange={(e: SliderChangeEvent) => field.onChange(e.value as number[])} className="text-xs p-0 m-0" range style={{width: '100%', alignSelf: 'center',verticalAlign: 'middle'}} min={returnBounds("Percentage").min} max={returnBounds("Percentage").max}/>
                                    <span className="text-xs p-0 m-0 mt-2" style={{ textAlign: 'center' }}>{field.value[0]} - {field.value[1]}</span>
                                    </div>
                                <span className="p-inputgroup-addon text-xs p-2 m-0">%</span>
                                {getFormErrorMessage(field.name)}
                            </div>
                            )}
                        />
                        <Controller
                        name="horizontal_pattern_occurrence"
                        defaultValue={HPOccurrence}
                        control={control}
                        render={({ field, fieldState }) => (
                            <div className="card flex w-full p-inputgroup mt-3 pt-1">
                                <span className="p-inputgroup-addon text-xs p-2 pr-4 pl-4 m-0">Occurrence:</span>
                                    <div className="card flex flex-column w-full gap-0">
                                    <Slider id={field.name} name={field.name} value={field.value as any} onChange={(e: SliderChangeEvent) => field.onChange(e.value as number[])} className="text-xs p-0 m-0" range style={{width: '100%', alignSelf: 'center',verticalAlign: 'middle'}} min={returnBounds("Percentage").min} max={returnBounds("Percentage").max}/>
                                    <span className="text-xs p-0 m-0 mt-2" style={{ textAlign: 'center' }}>{field.value[0]} - {field.value[1]}</span>
                                    </div>
                                <span className="p-inputgroup-addon text-xs p-2 m-0">%</span>
                                {getFormErrorMessage(field.name)}
                            </div>
                            )}
                        />
                         <Controller
                        name="horizontal_pattern_type"
                        defaultValue={HPType}
                        control={control}
                        render={({ field, fieldState }) => (
                            <div className="card flex w-full p-inputgroup mt-3 pt-1">
                                <span className="p-inputgroup-addon text-s p-0 m-0">
                                    <i className="pi pi-pencil"></i>
                                </span>
                                <span className="p-float-label w-full">                        
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-s  p-2 m-0" onChange={(e) => field.onChange(e.target.value)} style={{ width: '100%' }} />
                                    <label htmlFor={field.name}>Pattern Type</label>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </div>
                            )}
                        />
                    </div>
                    <div className="card" style={{ textAlign: 'right' }} >                        
                        <Button label="Add" icon="pi pi-angle-double-down" type="submit" autoFocus size="small" iconPos="right" style={{ backgroundColor: 'var(--highlight-bg)', color: 'var(--error-100)', borderRadius: 'var(--border-radius)' }} />
                    </div>
                    </form>
                </div>
            </div>
            <div className="card flex flex-column justify-content-center gap-1" >                
                <div><b>Select the relevant Horizontal Pattern:</b>
                    <TabView onTabClose={(e: TabViewTabChangeEvent) => promptHPDelete(e.index)} className="m-0 p-0">
                        {horizontalPatterns?.map((HP)=>(
                            HP.class_id == displayClass_HP?
                                <TabPanel key={HP.horizontal_pattern_id} header={HP.name} leftIcon="pi pi-fw pi-minus mr-2" className="m-0 p-0" closable closeIcon="pi pi-fw pi-delete-left" >
                                    <h4>Add Strata to {HP.name}</h4>
                                    <form id={HP.horizontal_pattern_id} name={HP.name} onSubmit={handleSubmit1(submitStratum)} className="flex flex-column justify-contents-center">
                                    <div className="card flex flex-row">
                                        {strata?.map((Stratum)=>(                                        
                                            parseInt(Stratum.HPID) === parseInt(HP.horizontal_pattern_id)? <Chip key={Stratum.stratumID} className="text-xs green" label={Stratum.name} removable onRemove={()=>promptStratumDelete(Stratum.stratumID)} /> : undefined
                                        ))}
                                    </div>                             
                                    <div className="w-full card justify-content-center gap-3">                                    
                                        <Controller
                                        name={"hp_ID-"+StratumID}                                 
                                        control={control1}
                                        render={({ field, fieldState }) => (
                                            <input type="hidden" id={field.name} name={field.name} value={field.value} defaultValue={HP.horizontal_pattern_id} />
                                            )}
                                        />  
                                        <Controller
                                        name={"stratum_id"}
                                        control={control1}
                                        defaultValue={StratumID}
                                        render={({ field, fieldState }) => (
                                            <div>                                        
                                            <input type="hidden" id={field.name} name={field.name} value={StratumID} />
                                            </div>
                                            )}                                      
                                        />
                                        <Controller
                                        name={"stratum_name-"+HP.horizontal_pattern_id}
                                        defaultValue={"Stratum "+StratumID}
                                        control={control1}
                                        rules={{ required: 'Name is required.' }}
                                        render={({ field, fieldState }) => (
                                            <div className="card flex w-full p-inputgroup mt-3 pt-1">
                                                <span className="p-inputgroup-addon text-s p-0 m-0">
                                                    <i className="pi pi-pencil"></i>
                                                </span>
                                                <span className="p-float-label w-full">                        
                                                    <InputText autoFocus id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-s  p-2 m-0" onChange={(e) => field.onChange(e.target.value)} style={{ width: '100%' }} />
                                                    <label htmlFor={field.name}>Stratum Name</label>
                                                </span>
                                                {getFormErrorMessage1(field.name)}
                                            </div>
                                            )}
                                        />
                                        <Controller
                                        name={"stratum_description-"+HP.horizontal_pattern_id}
                                        defaultValue="A layer in the Horizontal Pattern"
                                        control={control1}
                                        rules={{ required: 'Description is required.' }}
                                        render={({ field, fieldState }) => (
                                            <div className="card flex w-full p-inputgroup mt-3 pt-1">
                                                <span className="p-inputgroup-addon text-s p-0 m-0">
                                                    <i className="pi pi-pencil"></i>
                                                </span>
                                                <span className="p-float-label w-full">                        
                                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-s  p-2 m-0" onChange={(e) => field.onChange(e.target.value)} style={{ width: '100%'}} />
                                                    <label htmlFor={field.name}>Description</label>
                                                </span>
                                                {getFormErrorMessage1(field.name)}
                                            </div>
                                            )}
                                        />
                                        <Controller
                                        name={"stratum_presenceType-"+HP.horizontal_pattern_id}
                                        defaultValue="Fixed"
                                        control={control1}
                                        rules={{ required: 'Presence Type is required.' }}
                                        render={({ field, fieldState }) => (
                                            <div className="card flex w-full p-inputgroup mt-3 pt-1">
                                                <span className="p-inputgroup-addon text-s p-0 m-0">
                                                    <i className="pi pi-pencil"></i>
                                                </span>
                                                <span className="p-float-label w-full">
                                                    <Dropdown id={field.name} inputId={field.name} value={field.value} editable onChange={field.onChange} inputRef={field.ref} options={OptionsBus['PresenceTypes']} optionLabel="label" placeholder="Select a Presence Type" className="p-inputtext-sm text-s p-0 m-0" />
                                                    <label htmlFor={field.name}>Presence Type</label>
                                                </span>                                
                                                {getFormErrorMessage1(field.name)}
                                            </div>
                                            )}
                                        />
                                        <Controller
                                        name={"stratum_portioning-"+HP.horizontal_pattern_id}
                                        control={control1}
                                        defaultValue={[returnBounds("Percentage").min,returnBounds("Percentage").max]}
                                        rules={{ required: 'Portioning is required.' }}
                                        render={({ field, fieldState }) => (
                                            <div className="card flex w-full p-inputgroup mt-3 pt-1">
                                                <span className="p-inputgroup-addon text-xs p-2 m-0">Portioning:</span>
                                                    <div className="card flex flex-column w-full gap-0">
                                                    <Slider id={field.name} name={field.name} value={field.value as any} onChange={(e: SliderChangeEvent) => field.onChange(e.value as number[])} className="text-xs p-0 m-0" range style={{width: '100%', alignSelf: 'center',verticalAlign: 'middle'}} min={returnBounds("Percentage").min} max={returnBounds("Percentage").max}/>
                                                    <span className="text-xs p-0 m-0 mt-2" style={{ textAlign: 'center' }}>{field.value[0]} - {field.value[1]}</span>
                                                    </div>
                                                <span className="p-inputgroup-addon text-xs p-2 m-0">%</span>
                                                {getFormErrorMessage1(field.name)}
                                            </div>
                                            )}
                                        />
                                        <div className="flex flex-row">
                                            <Controller
                                            name={"stratum_onTop-"+HP.horizontal_pattern_id}
                                            defaultValue=""
                                            control={control1}                                    
                                            render={({ field, fieldState }) => (
                                                    <div className="card flex w-full p-inputgroup mt-3 pt-1">
                                                        <span className="p-inputgroup-addon text-s p-0 m-0">
                                                            <i className="pi pi-pencil"></i>
                                                        </span>
                                                        <span className="p-float-label w-full">
                                                            <Dropdown id={field.name} inputId={field.name} value={field.value} onChange={field.onChange} inputRef={field.ref} options={returnStrata(HP.horizontal_pattern_id)} optionLabel="name" placeholder="Select a Stratum" className="p-inputtext-sm text-s  p-0 m-0" />
                                                            <label htmlFor={field.name}>On Top</label>
                                                        </span>
                                                        {getFormErrorMessage1(field.name)}
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                            name={"stratum_onTopType-"+HP.horizontal_pattern_id}
                                            defaultValue=""
                                            control={control1}                                    
                                            render={({ field, fieldState }) => (
                                                    <div className="card flex w-full p-inputgroup mt-3 pt-1">                                                    
                                                        <span className="p-float-label w-full">
                                                            <Dropdown id={field.name} inputId={field.name} value={field.value} onChange={field.onChange} inputRef={field.ref} options={OptionsBus['OnTopTypes']} optionLabel="label" placeholder="On top type" className="p-inputtext-sm text-s  p-0 m-0" />
                                                            <label htmlFor={field.name}>Type</label>
                                                        </span>
                                                        {getFormErrorMessage1(field.name)}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="card" style={{ textAlign: 'right' }} >                        
                                        <Button id={"stratum_name-"+HP.horizontal_pattern_id} label={"Save "+HP.name+"'s Stratum"} icon="pi pi-save" type="submit" size="small" iconPos="right" style={{ backgroundColor: 'var(--highlight-bg)', color: 'var(--error-100)', borderRadius: 'var(--border-radius)' }} />
                                    </div>
                                    </form>
                                </TabPanel>
                            : null
                        ))}
                    </TabView>
                                       
                </div>
                <div className="card" style={{ textAlign: 'right' }} >
                    <Button label="Back" icon="pi pi-arrow-left" onClick={() => {setGuide2Visible(true); setGuide3Visible(false);setRerenderTrigger('Strata');}} text size="small" />
                    <Button label="Next" icon="pi pi-arrow-right" onClick={() => processStrata()} size="small" iconPos="right" className="align-self-end" />
                </div>
            </div>            
        </div>
    )
}
