import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { FloatLabel } from 'primereact/floatlabel';
import { TabView, TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Messages } from 'primereact/messages';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

import 'primeicons/primeicons.css';

// Types for all levels of nesting
interface BaseRule {
  lower_bound: string;
  lower_tolerance: string;
  uppper_bound: string;
  upper_tolerance: string;
  condition: 'equals' | 'between' | 'any';
}

interface CharacteristicRule extends BaseRule {
  object: string;
}

interface PropertyRule extends BaseRule {
  object: string;
  presence: 'required' | 'optional';
}

interface Characteristic {
  id: number;
  name: string;
  priority: number;
  rules: CharacteristicRule[];
}

interface Property {
  id: number;
  name: string;
  priority: number;
  rules: PropertyRule[];
}

interface ElementRules {
  characteristics: Characteristic[];
  properties: Property[];
}

interface Element {
  id: number;
  priority: number;
  element: string;
  rules: ElementRules;
}

interface StrataRule {
  object: string;
  presence: 'required' | 'optional';
}

interface Strata {
  id: number;
  priority: number;
  rules: StrataRule[];
}

interface CoverOccurrence extends BaseRule {
  object: string;
  presence: 'required' | 'optional';
}

interface HorizontalPattern {
  object: string;
  presence: 'required' | 'optional';
  cover: BaseRule;
  occurrence: BaseRule;
}

interface HorizontalPatterns {
  id: number;
  priority: number;
  rules: HorizontalPattern[];
}

interface ClassRule {
  object: string;
  presence: 'required' | 'optional';
  map_code: BaseRule;
}

interface Classes {
  id: number;
  priority: number;
  rules: ClassRule[];
}

interface Rule {
  id: number;
  name: string;
  priority: number;
  rules: {
    elements: Element[];
    strata: Strata;
    horizontalPatterns: HorizontalPatterns;
    classes: Classes;
  };
}

interface RuleSet {
  inclusive: Rule[];
  exclusive: Rule[];
}

export const RuleBuilder = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentRuleType, setCurrentRuleType] = useState<'inclusive' | 'exclusive'>('inclusive');
  const [tabValue, setTabValue] = useState(0);
  const [validationError, setValidationError] = useState<string | null>(null);
  
  // Initialize with empty ruleset
  const [ruleSet, setRuleSet] = useState<RuleSet>({
    inclusive: [],
    exclusive: []
  });

  // Current form states
  const [currentRule, setCurrentRule] = useState({
    name: '',
    priority: 1
  });
  
  const [currentElement, setCurrentElement] = useState({
    element: '',
    priority: 1
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setCurrentRuleType(newValue === 0 ? 'inclusive' : 'exclusive');
  };

  const addRule = () => {
    if (!currentRule.name) {
      setValidationError('Rule name is required');
      return;
    }

    const newRule: Rule = {
      id: Date.now(),
      name: currentRule.name,
      priority: currentRule.priority,
      rules: {
        elements: [],
        strata: {
          id: 1,
          priority: 1,
          rules: [{ object: "1", presence: "required" }]
        },
        horizontalPatterns: {
          id: 1,
          priority: 1,
          rules: [{
            object: "1",
            presence: "required",
            cover: {
              lower_bound: "0",
              lower_tolerance: "0",
              uppper_bound: "0",
              upper_tolerance: "0",
              condition: "between"
            },
            occurrence: {
              lower_bound: "0",
              lower_tolerance: "0",
              uppper_bound: "0",
              upper_tolerance: "0",
              condition: "between"
            }
          }]
        },
        classes: {
          id: 1,
          priority: 1,
          rules: [{
            object: "1",
            presence: "required",
            map_code: {
              lower_bound: "*",
              lower_tolerance: "*",
              uppper_bound: "*",
              upper_tolerance: "*",
              condition: "any"
            }
          }]
        }
      }
    };

    setRuleSet(prev => ({
      ...prev,
      [currentRuleType]: [...prev[currentRuleType], newRule]
    }));

    setCurrentRule({ name: '', priority: 1 });
    setValidationError(null);
  };

  const addElement = (ruleId: number) => {
    if (!currentElement.element) {
      setValidationError('Element name is required');
      return;
    }

    const newElement: Element = {
      id: Date.now(),
      element: currentElement.element,
      priority: currentElement.priority,
      rules: {
        characteristics: [],
        properties: []
      }
    };

    setRuleSet(prev => ({
      ...prev,
      [currentRuleType]: prev[currentRuleType].map(rule => {
        if (rule.id === ruleId) {
          return {
            ...rule,
            rules: {
              ...rule.rules,
              elements: [...rule.rules.elements, newElement]
            }
          };
        }
        return rule;
      })
    }));

    setCurrentElement({ element: '', priority: 1 });
  };

  const deleteRule = (ruleId: number) => {
    setRuleSet(prev => ({
      ...prev,
      [currentRuleType]: prev[currentRuleType].filter(rule => rule.id !== ruleId)
    }));
  };

  const deleteElement = (ruleId: number, elementId: number) => {
    setRuleSet(prev => ({
      ...prev,
      [currentRuleType]: prev[currentRuleType].map(rule => {
        if (rule.id === ruleId) {
          return {
            ...rule,
            rules: {
              ...rule.rules,
              elements: rule.rules.elements.filter(el => el.id !== elementId)
            }
          };
        }
        return rule;
      })
    }));
  };

  const exportRules = () => {
    const jsonStr = JSON.stringify(ruleSet, null, 2);
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'rules.json';
    a.click();
    URL.revokeObjectURL(url);
  };

  const importRules = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const imported = JSON.parse(e.target?.result as string);
        if (imported.inclusive && imported.exclusive) {
          setRuleSet(imported);
        } else {
          setValidationError('Invalid rule format');
        }
      } catch (error) {
        setValidationError('Error parsing JSON file');
      }
    };
    reader.readAsText(file);
  };

  const msgs = useRef<Messages>(null);
  msgs.current?.show({ id: '1', sticky: true, severity: 'error', summary: 'errir', detail: validationError, closable: true });

  const stepperRef = useRef(null);
  const toast = useRef<Toast>(null);
  const [referenceLegend,setReferenceLegend] = useState(null);
  const referenceLegends = [        
    {name: "Copernicus Global Land Service Global Land Cover", ref:"Copernicus"},
    {name: "ESA WorldCover 2020", ref:"ESA"},
    {name: "Climate Change Initiative Global Land Cover", ref:"Climate"},
    {name: "The Global 2000-2020 Land Cover and Land Use Chage Dataset", ref:"Change"},
    {name: "GlobCover Land Cover Maps", ref:"GlobCover"},
    {name: "The Global Land Cover-SHARE (GLC-SHARE)", ref:"GLC-SHARE"},
    {name: "Global Land Cover Mapping: A POK Based Approach (GlobeLand30)", ref: "GlobeLand30"},
    {name: "Intergovernmental Panel on Climate Change (IPCC)", ref:"IPCC"},
    {name: "International Geosphere-Biosphere Programme", ref:"IGBP"},
    {name: "United Nations Convention to Combat Desertification (UNCCD)", ref:"UNCCD"},
    {name: "ESRI Global Land Use Land Cover", ref: "ESRI"},
    {name: "Global 30m surface coverage fine classification products", ref:"Global30"},
    {name: "The Sytem of Environmental-Economic Accounting - Ecosystem Accounting (SEEA EA)", ref:"SEEA"},
    {name: "Land use and land cover classification system for use with remote sensor data (Anderson)", ref:"Anderson"}
  ];
  const IPCCStructure = {
    classes: [
      {id: 1, name: "Artificial Surfaces"}
    ],
    horizontalPatterns: [     
      {id: 1, name: "Horizontal Pattern"}
    ],
    strata: [
      {id: 1, name: "Stratum"}
    ],
    elements: [
      {id: 1, name: "Building"},
      {id: 2, name: "Linear Surface"}
    ]
  }
  const SEEAStructure = {
    classes: [
      {id: 1, name: "Artificial Surfaces"}
    ],
    horizontalPatterns: [     
      {id: 1, name: "Horizontal Pattern"}
    ],
    strata: [
      {id: 1, name: "Stratum"}
    ],
    elements: [
      {id: 1, name: "Building"},
      {id: 2, name: "Linear Surface"}
    ]
  }
  const MODIS250mStructure = {
    classes: [
      {id: 1, name: "Artificial Surfaces"}
    ],
    horizontalPatterns: [     
      {id: 1, name: "Horizontal Pattern"}
    ],
    strata: [
      {id: 1, name: "Stratum"}
    ],
    elements: [
      {id: 1, name: "Building"},
      {id: 2, name: "Linear Surface"}
    ]
  }
  const MERIS350mStructure = {
    classes: [
      {id: 1, name: "Artificial Surfaces"}
    ],
    horizontalPatterns: [     
      {id: 1, name: "Horizontal Pattern"}
    ],
    strata: [
      {id: 1, name: "Stratum"}
    ],
    elements: [
      {id: 1, name: "Building"},
      {id: 2, name: "Linear Surface"}
    ]
  }
  const ESA10mStructure = {
    classes: [
      {id: 1, name: "Artificial Surfaces"}
    ],
    horizontalPatterns: [     
      {id: 1, name: "Horizontal Pattern"}
    ],
    strata: [
      {id: 1, name: "Stratum"}
    ],
    elements: [
      {id: 1, name: "Building"},
      {id: 2, name: "Linear Surface"}
    ]
  }
  const ESA20mStructure = {
    classes: [
      {id: 1, name: "Artificial Surfaces"}
    ],
    horizontalPatterns: [     
      {id: 1, name: "Horizontal Pattern"}
    ],
    strata: [
      {id: 1, name: "Stratum"}
    ],
    elements: [
      {id: 1, name: "Building"},
      {id: 2, name: "Linear Surface"}
    ]
  }
  const China30mStructure = {
    classes: [
      {id: 1, name: "Artificial Surfaces"}
    ],
    horizontalPatterns: [     
      {id: 1, name: "Horizontal Pattern"}
    ],
    strata: [
      {id: 1, name: "Stratum"}
    ],
    elements: [
      {id: 1, name: "Building"},
      {id: 2, name: "Linear Surface"}
    ]
  }
  const GlobcoverStructure = {
    classes: [
      {id: 1, name: "Artificial Surfaces"}
    ],
    horizontalPatterns: [     
      {id: 1, name: "Horizontal Pattern"}
    ],
    strata: [
      {id: 1, name: "Stratum"}
    ],
    elements: [
      {id: 1, name: "Building"},
      {id: 2, name: "Linear Surface"}
    ]
  }
  const CORINEStructure = {
    classes: [
      {id: 1, name: "Artificial Surfaces"}
    ],
    horizontalPatterns: [     
      {id: 1, name: "Horizontal Pattern"}
    ],
    strata: [
      {id: 1, name: "Stratum"}
    ],
    elements: [
      {id: 1, name: "Building"},
      {id: 2, name: "Linear Surface"}
    ]
  }
  const AndersonStructure = {
    classes: [
      {id: 1, name: "Artificial Surfaces"}
    ],
    horizontalPatterns: [     
      {id: 1, name: "Horizontal Pattern"}
    ],
    strata: [
      {id: 1, name: "Stratum"}
    ],
    elements: [
      {id: 1, name: "Building"},
      {id: 2, name: "Linear Surface"}
    ]
  }

  const [detailsClass,setDetailsClass] = useState(null);
  const classDetails = [   
      {label: "Cover", parameters: ['setCover','range','0','100','10','20']},
      {label: "Height", parameters: ['setHeight','range','0','100','10','20']},
      {label: "Phenology", parameters: ['setPhenology','option','phenology']},
      {label: "Salinity", parameters: ['setSalinity','multiOption','salinity']},
      {label: "Species", parameters: ['setMapCode','text']}
  ];  
  const detailParameters = {
    text: "name",
    option: "options",
    range: {bounds: {lower: 0, upper: 100}, tolerance: {lower: 10, upper: 20}}
  }
  const presenceOptions = [      
    {label: "Mandatory Standard (Ms)", value: "Ms"},
    {label: "Mandatory Inclusive (Mi)", value: "Mi"}
  ];
  const presenceSelectiveOptions = [      
    {label: "Horizontal Pattern (h)", value: "h"},
    {label: "Time (t)", value: "t"}
  ];
  const phenologyOptions = [      
    {label: "Deciduous", value: "Deciduous"},
    {label: "Evergreen", value: "Evergreen"}
  ];
  const salinityOptions = [      
    {label: "Fresh", value: "Fresh"},
    {label: "Brackish", value: "Brackish"},
    {label: "Saline", value: "Saline"},
    {label: "Brine", value: "Brine"},
  ];
  const exclusions = [
    {label: "Water Body", value: "Water Body"},    
    {label: "Snow", value: "Snow"},
    {label: "Ice", value: "Ice"}
  ];
  const genEntry = (parameters)=>{
    let enterer = null;
    if(parameters[1] === "text"){
      enterer = <>
        <td>
          <input type='text' name={parameters[0]} id={parameters[0]} />
        </td>
      </>
    }
    else if (parameters[1] === "range"){
      enterer = <>        
        <td>
          <div className='flex flex-column m-0 p-0 gap-1'>
            <div className='flex flex-column m-0 p-0 align-items-center justify-content-center'>
              Upper:
              <div className='flex flex-row m-0 p-0 align-items-center justify-content-center'>Bounds: <input type='number' name='upper_bound' id='upper_bound' size={2} /> Threshold: <input type='number' name='upper_bound' id='upper_bound' size={2} /></div>
            </div>
            <div className='flex flex-column m-0 p-0 align-items-center justify-content-center'>
              Lower:
              <div className='flex flex-row m-0 p-0 align-items-center justify-content-center'>Bounds: <input type='number' name='lower_bound' id='lower_bound' size={2} /> Threshold: <input type='number' name='lower_bound' id='lower_bound' size={2} /></div>
            </div>
          </div>
        </td>
      </>
    }
    else if(parameters[1] === "option"){
      let options = null;
      eval('options = '+`${parameters[2]}Options`);
      enterer = <>
        <td>
          <select name={parameters[0]} id={parameters[0]}>
            {options.map((option)=>(
              <option value={option.value}>{option.label}</option>
            ))}
          </select>
        </td>
      </>
    }
    else if(parameters[1] === "multiOption"){
      let options = null;
      eval('options = '+`${parameters[2]}Options`);
      enterer = <>
        <td>          
            {options.map((option)=>(
                <div>
                <input type="checkbox" name={parameters[0]+option.value} id={parameters[0]+option.value} value={option.value} />
                <label htmlFor={parameters[0]+option.value}>{option.label}</label><br/>
                </div>
            ))}          
        </td>
      </>
    }
      
    return(
      enterer
    )
  }

  const genLegendStructure = (ref)=>{
    let dropper = null;
    if(ref != undefined){
      let classes = null;    
      let horizontalPatterns = null;
      let strata = null;
      let elements = null;
      eval('classes = '+`${ref}Structure['classes']`);
      eval('horizontalPatterns = '+`${ref}Structure['horizontalPatterns']`);
      eval('strata = '+`${ref}Structure['strata']`);
      eval('elements = '+`${ref}Structure['elements']`);
      dropper = <select name="detail" id="detail" size={10}>
                  <optgroup label="Classes">
                    {classes.map((option)=>(
                      <option value={option.id} onClick={()=>document.getElementById('RuleTable').style.display='block'}>{option.name}</option>
                    ))}                    
                  </optgroup>
                  <optgroup label="Horizontal Patterns">
                    {horizontalPatterns.map((option)=>(
                      <option value={option.id} onClick={()=>document.getElementById('RuleTable').style.display='block'}>{option.name}</option>
                    ))}
                  </optgroup>
                  <optgroup label="Strata">
                    {strata.map((option)=>(
                      <option value={option.id} onClick={()=>document.getElementById('RuleTable').style.display='block'}>{option.name}</option>
                    ))}
                  </optgroup>
                  <optgroup label="Elements">
                    {elements.map((option)=>(
                      <option value={option.id} onClick={()=>document.getElementById('RuleTable').style.display='block'}>{option.name}</option>
                    ))}
                  </optgroup>
                </select>;
    }

    return(      
      dropper
    )
  }
  
  return (
    <Card style={{ margin: '0 auto', padding: 2 }}>
      <div className="card">
          <Stepper ref={stepperRef} orientation="vertical" linear>
              <StepperPanel header={"Reference Legend "+referenceLegend?.name}>
                  <div className="flex flex-column">
                      <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium p-1" style={{ overflow: 'auto' }}>                      
                          <Dropdown value={referenceLegend} onChange={(e: DropdownChangeEvent) => {setReferenceLegend(e.value);stepperRef.current.nextCallback()}} options={referenceLegends} optionLabel="name" placeholder="Select the Reference Legend" highlightOnSelect={true} required />
                      </div>
                  </div>                  
              </StepperPanel>
              <StepperPanel header="Rules">
                  <div className="flex flex-column w-full">
                      <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium flex-column mb-1" style={{ overflow: 'auto' }}>
                        Add Rules                        
                          <div className='flex flex-row p-1 align-items-center justify-content-center'>
                            <input type='text' placeholder='Name' id='RuleName' value={currentRule.name} onChange={(e) => setCurrentRule(prev => ({ ...prev, name: e.target.value }))}/>
                            <input type='number' placeholder='Priority' size={5} id='Priority' value={currentRule.priority} onChange={(e) => setCurrentRule(prev => ({ ...prev, priority: Number(e.target.value) }))}/>
                            <Button onClick={addRule}>Add Rule</Button>
                          </div>

                        {/* Rules Display */}
                        {ruleSet[currentRuleType].map(rule => (
                          <>
                            {/* Header */}
                            <Card style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: 1 }}>
                              Rule: {rule.name} (Priority: {rule.priority})
                              <i className="pi pi-trash" onClick={() => deleteRule(rule.id)}></i>
                              <div className="flex flex-row gap-2">
                                <div>                            
                                  {genLegendStructure(referenceLegend?.ref)}
                                </div>
                                <div>
                                  <table id="RuleTable" style={{ display: 'none' }}>
                                    <tbody>
                                      <tr style={{ backgroundColor: '#ccc' }}>
                                        <td style={{ textAlign: 'center' }}>Presence</td>
                                        <td style={{ textAlign: 'center' }}>Property</td>
                                        <td style={{ textAlign: 'center' }}>Condition</td>
                                        <td style={{ textAlign: 'center' }}>Exclusion</td>
                                        <td style={{ textAlign: 'center' }}></td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <select>
                                            <option>Prescence Type</option>
                                            {presenceOptions.map((presence)=>(
                                              <option value={presence.value}>{presence.label}</option>
                                            ))}
                                          </select>
                                        </td>
                                        <td>
                                          <select>
                                            <option>Select Property</option>
                                            {classDetails.map((clss)=>(
                                              <option value={clss.parameters} onClick={() => setDetailsClass(clss.parameters)}>{clss.label}</option>
                                            ))}                                      
                                          </select>  
                                        </td>                                  
                                        {detailsClass && genEntry(detailsClass)}
                                        <td>
                                          <select>
                                            <option>Exclusions</option>
                                            {exclusions.map((exclusion)=>(
                                              <option value={exclusion.value}>{exclusion.label}</option>
                                            ))}
                                          </select>
                                        </td>
                                        <td>
                                          <Button size='small' severity='success' outlined>Add</Button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Card>
                          </>
                        ))}                        
                      </div>
                  </div>
                  <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium flex-column" style={{ overflow: 'auto' }}>
                    {referenceLegend?.name+" Rules"}
                  </div>
                  <div className="flex py-4 gap-2">
                      <Button label="Back to Reference Legends" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                      <Button label="Rule Functions" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
                  </div>
              </StepperPanel>
              <StepperPanel header="Rule Functions">
                  <div className="flex flex-column h-12rem">
                      <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={importRules}
                          accept=".json"
                          style={{ display: 'none' }}
                        />
                        <Button
                          onClick={() => fileInputRef.current?.click()}
                          style={{ marginRight: 1 }}
                          icon="pi pi-cloud-upload"
                          outlined 
                        >
                          Import
                        </Button>
                        <Button                
                          onClick={exportRules}
                          icon="pi pi-cloud-download" 
                        >
                          Export
                        </Button>
                      </div>
                  </div>
                  <div className="flex py-4">
                      <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                  </div>
              </StepperPanel>
          </Stepper>
      </div>
      <Card>        
          
          {/* Tabs 
          <TabView>
            <TabPanel header="Inclusive Rules">
                <p className="m-0">
                    Inclusive Rules
                </p>
            </TabPanel>
            <TabPanel header="Exclusive Rules">
                <p className="m-0">
                    Exclusive Rules
                </p>
            </TabPanel>
          </TabView>*/}

          {/* Error Display */}
          {validationError && (
            <Messages ref={msgs} />            
          )}

          {/* Add Rule Form */}
          <Card style={{ marginTop: 3, marginBottom: 4 }}>
            Add New Rule
            <Card style={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
              <FloatLabel>
                <InputText
                  id="RuleName"
                  value={currentRule.name}
                  onChange={(e) => setCurrentRule(prev => ({ ...prev, name: e.target.value }))}
                />
                <label htmlFor="RuleName">Rule Name</label>
              </FloatLabel>
              <FloatLabel>
                <InputNumber
                  id="Priority"
                  value={currentRule.priority}
                  onValueChange={(e) => setCurrentRule(prev => ({ ...prev, priority: Number(e.target.value) }))}
                />
                <label htmlFor="Priority">Priority</label>
              </FloatLabel>    
              <Button                
                onClick={addRule}
              >
                Add Rule
              </Button>
            </Card>
          </Card>

          {/* Rules Display */}
          {ruleSet[currentRuleType].map(rule => (
            <>
              {/* Header */}
              <Card style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                  {rule.name} (Priority: {rule.priority})
                <i className="pi pi-trash" onClick={() => deleteRule(rule.id)}></i>
              </Card>

              {/* Add Element Form */}
              <Card style={{ marginBottom: 3 }}>
                Add Element
                <Card style={{ display: 'flex', gap: 2, marginTop: 1 }}>
                  <FloatLabel>
                    <InputText
                      id="ElementName"
                      value={currentElement.element}
                      onChange={(e) => setCurrentElement(prev => ({ ...prev, element: e.target.value }))}
                    />
                    <label htmlFor="ElementName">Element Name</label>
                  </FloatLabel>
                  <FloatLabel>
                    <InputNumber
                      id="Priority"
                      value={currentElement.priority}
                      onValueChange={(e) => setCurrentElement(prev => ({ ...prev, priority: Number(e.target.value) }))}
                    />
                    <label htmlFor="Priority">Priority</label>
                  </FloatLabel>                  
                  <Button                    
                    onClick={() => addElement(rule.id)}
                  >
                    Add Element
                  </Button>
                </Card>
              </Card>

              <Divider />

              {/* Elements List */}
              {rule.rules.elements.map(element => (
                <Card key={element.id} style={{ marginBottom: 2, padding: 2 }}>
                  <Card style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>                    
                      Element: {element.element} (Priority: {element.priority})
                    <i className="pi pi-trash" onClick={() => deleteElement(rule.id, element.id)}></i>                    
                  </Card>
                </Card>
              ))}
          </>
          ))}
      </Card>
    </Card>
  );
};